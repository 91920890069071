import React from 'react';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';

// import { leaderShip } from '../../../lang.json'


function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="next">
      {/* <i className="fal fa-long-arrow-right" />
      <FontAwesomeIcon icon={faLongArrowAltRight}/> */}
    </span>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="prev">
      {/* <FontAwesomeIcon icon={faLongArrowAltLeft}/> */}
    </span>
  );

}

class LogoPart extends React.Component {

  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        }
      ]


    };

    return (
      <div className="leadership-area gray-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">

            </div>
          </div>
          <Slider className="row leadership-active" {...settings}>

            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM1.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM2.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM3.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM4.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM5.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM6.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM7.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM8.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM9.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM10.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM11.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM12.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM13.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM14.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM15.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM16.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM17.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM18.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM19.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM20.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM21.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM22.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM23.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM24.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM25.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM26.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM27.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM28.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM29.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM30.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM31.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM32.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM33.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM34.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM35.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM36.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM37.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM38.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM39.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM40.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM41.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM42.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM43.jpg' alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="leadership-item mt-30">
                <img src='/assets/images/logos/logos/IM44.jpg' alt="" />
              </div>
            </div>
           
            
          </Slider>
          {/* row */}
        </div>
        {/* container */}
      </div>
    );
  }
}

export default LogoPart;