import React from 'react';

import {pages} from '../../../lang.json'

const data = pages.services

class ServicesItemPart extends React.Component {

    render() {
        return (
          <div className="services-item-area pt-90 pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-1.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-eye-dropper" />
                      <h4 className="title">{this.props.lang == 'English' ? data.item[0].title.en : data.item[0].title.ar}</h4>
                      <p>
                      {this.props.lang == 'English' ? data.item[0].description.en : data.item[0].description.ar} 
                      { /* We provide total manpower solutions for all our clients with positive approach.. */}
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-2.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-gavel" />
                      <h4 className="title">{this.props.lang == 'English' ? data.item[1].title.en : data.item[1].title.ar}</h4>
                      <p>
                      {this.props.lang == 'English' ? data.item[1].description.en : data.item[1].description.ar}
                      {/* who strive for professional and personal growth */}
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-3.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-map-marked-alt" />
                      <h4 className="title">{this.props.lang == 'English' ? data.item[2].title.en : data.item[2].title.ar}</h4>
                      <p>
                      {this.props.lang == 'English' ? data.item[2].description.en : data.item[2].description.ar}
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </div>
               
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
         );
    }
}

export default ServicesItemPart;
