import React from 'react';
import { Link } from 'react-router-dom';

class ServicesPlansPart extends React.Component {

    render() {
        return (
          <div className="services-plans-area">
          
          </div>
         );
    }
}

export default ServicesPlansPart;
