import React from 'react';
import { Link } from 'react-router-dom';
import './Index.scss';
import {pages} from '../../../lang.json'

const data = pages.aboutus.section[0]

class Index extends React.Component {

    render() { 
        return ( 
          <div className="about-experience-area pb-120">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="experience-item">
                    <span>{this.props.lang == 'English' ? data.name.en : data.name.ar}</span>
                    <h2 className="title">{this.props.lang == 'English' ? data.title.en : data.title.ar}</h2>
                  </div>
                  {/* experience item */}
                </div>
                <div className="col-lg-6 offset-lg-1">
                  <div className="experience-item">
                    <p>
                    {this.props.lang == 'English' ? data.description.en : data.description.ar}
                    </p>
                    <Link to={"about"}>{this.props.lang == 'English' ? data.more.en : data.more.ar}</Link>
                  </div>
                  {/* experience item */}
                </div>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration=".5s"
                  data-wow-delay=".2s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-1.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">{this.props.lang == 'English' ? data.item[0].title.en : data.item[0].title.ar}</h5>
                      <p>
                      {this.props.lang == 'English' ? data.item[0].description.en : data.item[0].description.ar}
                      </p>
                      <Link to={"about"}>{this.props.lang == 'English' ? data.more.en : data.more.ar}</Link>
                      <i className="fal fa-laptop-code" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-2.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">{this.props.lang == 'English' ? data.item[1].title.en : data.item[1].title.ar}</h5>
                      <p>
                      {this.props.lang == 'English' ? data.item[1].description.en : data.item[1].description.ar}
                        product.
                      </p>
                      <Link to="/home-1">{this.props.lang == 'English' ? data.more.en : data.more.ar}</Link>
                      <i className="fal fa-headphones-alt" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".6s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-3.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">{this.props.lang == 'English' ? data.item[2].title.en : data.item[2].title.ar}</h5>
                      <p>
                      {this.props.lang == 'English' ? data.item[2].description.en : data.item[2].description.ar}
                      </p>
                      <Link to={"/about"}>{this.props.lang == 'English' ? data.more.en : data.more.ar}</Link>
                      <i className="fal fa-analytics" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
         );
    }
}
 
export default Index;