import React from 'react';
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom';
import {pages} from '../../../lang.json'

const data = pages.aboutus.section[1]


class AboutIntroPart extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal = event  => {
    this.setState({isOpen: true})
    event.preventDefault()
  }

    render() { 

        return ( 
          <div className="about-intro-area pt-120">
            <div className="container">
             <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='TdSA7gkVYU0' onClose={() => this.setState({isOpen: false})} />
              <div className="row">
                <div className="col-lg-6">
                  <div className="intro-thumb">
                    <img src="/assets/images/about-intro-thumb.jpg" alt="" />
                    <Link className="video-popup" to="http://bit.ly/2VuPnrx" onClick={this.openModal}>
                      <i className="fas fa-play" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="intri-content">
                    <span>{this.props.lang == 'English' ? data.name.en : data.name.ar}</span>
                    <h2 className="title">
                    {this.props.lang == 'English' ? data.title.first.en : data.title.first.ar} <br /> {this.props.lang == 'English' ? data.title.second.en : data.title.second.ar}
                    </h2>
                    <p className="text-1">
                    {this.props.lang == 'English' ? data.subtitle.en : data.subtitle.ar}
                    </p>
                    <p className="text-2">
                      {this.props.lang == 'English' ? data.description.enm : data.description.ar}
                    </p>
                    <Link
                      className="main-btn wow fadeInUp"
                      to={"/"}
                      data-wow-duration="1s"
                      data-wow-delay=".4s"
                    >
                      {this.props.lang == 'English' ? data.more.en : data.more.ar}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
         );
    }
}
 
export default AboutIntroPart;