import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {IntlProvider} from "react-intl";
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import en from "./assets/translations/en.json";
import ar from "./assets/translations/ar.json";


// const messages = {
//     'en': messages_en,
//     'ar': messages_ar
// };
const language = navigator.language.split(/[-_]/)[0];  // language without region code

// addLocaleData([...en, ...de]);

ReactDOM.render(
	<IntlProvider locale="en" messages={en}>
	<App />
	</IntlProvider>, 
	document.getElementById('root')
	);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
