import React from 'react';

import {leaderShip} from '../../../lang.json'

// const data = whatWeDo.item[0];

class PerformanceManagementPart extends React.Component {

    render() { 
        return ( 
          <div className="services-optimization-area pt-80 pb-120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-lg-1 order-2">
                  <div className="services-optimization-thumb mt-40">
                    <img src="assets/images/BaderAldoweesh.jpeg" alt="" />
                  </div>
                  <div className="services-optimization-thumb-2">
                   
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2 order-1">
                  <div className="services-optimization-content mt-40">
                    <span style={{fontSize: 20,textAlign: 'right'}}>{this.props.lang == 'English' ? leaderShip.member[0].en : leaderShip.member[0].ar} </span>
                    {this.props.lang == 'English' ?
                    <h3 className="title"> {leaderShip.member[0].name_en} </h3>
                    :
                    <h3 style={{textAlign: 'right'}} className="title text-sm-right"> {leaderShip.member[0].name_ar} </h3>
                    }
                    {this.props.lang == 'English' ?
                    <p className="text-sm-left">
                    {leaderShip.member[0].description_en}  
                 </p>
                    :
                    <p style={{textAlign: 'right'}} className="text-sm-right">
                    {leaderShip.member[0].description_ar1 + leaderShip.member[0].description_ar2}  
                 </p>
                    }

                    {/* <div className="action-support-item">
                      <p>{this.props.lang == 'English' ? data.button.en : data.button.ar}l</p>
                      <span>966 556264441</span>
                      <i className="fal fa-phone" />
                    </div> */}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
         );
    }
}
 
export default PerformanceManagementPart;