import React from 'react';

import { whatWeDo } from "../../../lang.json";

const data = whatWeDo.item[12]


class ProtectEmailSystemPart extends React.Component {

    render() { 
        return ( 
          <div className="services-optimization-area pt-80 pb-120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-lg-1 order-2">
                  <div className="services-optimization-thumb mt-40">
                    <img src="assets/images/mimecast.png" alt="" />
                  </div>
                  <div className="services-optimization-thumb-2">
                   
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2 order-1">
                  <div className="services-optimization-content mt-40">
                    <span>{this.props.lang == 'English' ? data.name.en : data.name.ar}</span>
                    <h3 className="title">{this.props.lang == 'English' ? data.title.en : data.title.ar}</h3>
                    <p>
                    {this.props.lang == 'English' ? data.description.en : data.description.ar}
                    </p>
               
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
         );
    }
}
 
export default ProtectEmailSystemPart;