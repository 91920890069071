import React from 'react';

import {whatWeDo} from '../../../lang.json'

const data = whatWeDo.item[1]


class DataMiningPart extends React.Component {

    render() { 
        return ( 
          <div className="services-optimization-area pt-80 pb-120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-lg-1 order-2">
                  <div className="services-optimization-thumb mt-40">
                    <img src="assets/images/qpr-2.png" alt="" />
                  </div>
                  <div className="services-optimization-thumb-2">
                   
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2 order-1">
                {this.props.lang == 'English' ? 
                  <div className="services-optimization-content mt-40">
                    {/* <span>{this.props.lang == 'English' ? data.name.en : data.name.ar}</span> */}
                    <h2><i>{"T"}</i></h2>
                    <p>{ data.title.en}</p>
                    <h2><i>{"D"}</i></h2>
                    <p>
                    {data.description.en}
                    </p>
                    {/* <div className="action-support-item">
                      <p>{this.props.lang == 'English' ? data.button.en : data.button.ar}</p>
                      <span>966 556264441</span>
                      <i className="fal fa-phone" />
                    </div> */}
                  </div>
                  :
                  <div className="services-optimization-content mt-40 text-sm-right">
                    <h2><i>{""}</i></h2>
                    <p>{data.title.ar}</p>
                    <h2><i>{""}</i></h2>
                    <p style={{textAlign: 'right'}}>
                    {data.description.ar}
                    </p>
                    {/* <div className="action-support-item">
                      <p>{this.props.lang == 'English' ? data.button.en : data.button.ar}</p>
                      <span>966 556264441</span>
                      <i className="fal fa-phone" />
                    </div> */}
                  </div>
                  }
                  </div>
              </div>
            </div>
          </div>
         );
    }
}
 
export default DataMiningPart;