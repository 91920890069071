import React from 'react';
import Footer from '../common/footer/Index';
import Header from '../common/header/Index';
import { Link } from 'react-router-dom';

import { pages } from "../../lang.json";

const data = pages.error

class NoMatch extends React.Component {

    render() { 
        return ( 
          <>
          <Header lang={this.props.lang} changeLang={this.props.changeLang}/>
            {
              /*====== ERROR PART START ======*/
            }
            <div className="row pt-120"></div>
            <div className="error-area pt-120 pb-115">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="error-item text-center">
                      <img src="/assets/images/error.png" alt="" />
                      <h2 className="title">
                      {this.props.lang == 'English' ? data.title.first.en : data.title.first.ar} <br /> {this.props.lang == 'English' ? data.title.second.en : data.title.second.ar}
                      </h2>
                      <span>{this.props.lang == 'English' ? data.description.en : data.description.ar}</span>
                      <p>
                        <Link to={'/'}>{this.props.lang == 'English' ? data.button.en : data.button.ar}</Link>
                      </p>
                    </div>
                    {/* error item */}
                  </div>
                </div>
              </div>
            </div>
            {
              /*====== ERROR PART ENDS ======*/
            }
            <Footer lang={this.props.lang}/>
            </>
         );
    }
}
 
export default NoMatch;