import React from 'react';
import Footer from '../common/footer/Index';
import Header from '../common/header/Index';
import BadrAldweeshPart from '../componets/BadrAldweeshPart/Index';

import { Link } from 'react-router-dom';

import {whatWeDo} from "../../lang.json"

const data = whatWeDo.item[0]

class PerformanceManagement extends React.Component {

    render() { 
        return ( 
          <>
            <Header lang={this.props.lang} changeLang={this.props.changeLang}/>
            {
              /*====== PAGE TITLE PART START ======*/
            }

            {
              /*====== PAGE TITLE PART ENDS ======*/
            }
            <BadrAldweeshPart lang={this.props.lang}/>
            <Footer lang={this.props.lang}/>
            </>
         );
    }
}
 
export default PerformanceManagement;