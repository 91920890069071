import React from 'react';
import Footer from '../../common/footer/Index';
import Header from '../../common/header/Index';
import { whatWeDo } from '../../../lang.json'
import { Link } from 'react-router-dom';

import ProcessManagementPart from '../../componets/ProcessManagementPart/Index';

const data = whatWeDo.item[3]
class ProcessManagement extends React.Component {

  render() { 
      return ( 
        <>
          <Header lang={this.props.lang} changeLang={this.props.changeLang}/>
          {
            /*====== PAGE TITLE PART START ======*/
          }
          <div className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-item text-center">
                    <h2 className="title">{this.props.lang == 'English' ? data.en : data.ar}</h2>
                    <nav aria-label="breadcrumb">

                    
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link  to={"/"}>{this.props.lang == 'English' ? data.path.root.en : data.path.root.ar} </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                        {this.props.lang == 'English' ? data.path.dest.en : data.path.dest.ar}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* page title */}
                </div>
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {
            /*====== PAGE TITLE PART ENDS ======*/
          }
          <ProcessManagementPart lang={this.props.lang}/>
          <Footer lang={this.props.lang}/>
          </>
       );
  }
}

export default ProcessManagement;