import React from "react";
import axios from "axios";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//import ReactDOM from 'react-dom'
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";
import { SERVER_URL } from "../../config";
import {faLongArrowAltRight,faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";

class IntroVideoPart extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    // get posts
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .get(`${SERVER_URL}/api/posts`, { headers: headers })
      .then((res) => {
        let posts = res.data.data;
        this.setState({ posts });
      })
      .catch((err) => console.log(err));
  }

  render() {
    if (this.state.posts.length > 0)
      return (
        <OwlCarousel
          className="owl-theme"
          loop
          margin={3}
          items={1}
          dots={false}
        >
          {this.state.posts.map((post) => {
            return (
              <div
                className="intro-video-area bg_cover"
                style={{
                  backgroundImage: "url(assets/images/intro-video-bg.jpg)",
                }}
                key={post.id}
              >
                <div className="intro-overlay">
                  <div className="container">
                    <div className="row" key={post.id}>
                      <div className="col-lg-6">
                        <div className="intro-video-content mt-30">
                          <h2 className="title">
                            {this.props.lang === "English"
                              ? post.title_english
                              : post.title_arabic}
                          </h2>
                          <p className="text-1">
                            {this.props.lang === "English"
                              ? post.content_english
                              : post.content_arabic}
                          </p>
                          {/* <Link
                            className="main-btn wow fadeInLeft"
                            data-wow-duration="1s"
                            data-wow-delay=".1s"
                            to={"/performance-management"}
                          >
                            Read More
                          </Link> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mt-30">
                          <img
                            src={
                              SERVER_URL + "/storage/upload/posts/" + post.image
                            }
                            style={{ height: "300px" }}
                            alt={post.title}
                          />
                        </div>
                      </div>
                        <FontAwesomeIcon icon={faLongArrowAltRight} />                       
                    </div>                    
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      );

    return (
      <div
        className="intro-video-area bg_cover"
        style={{ backgroundImage: "url(assets/images/intro-video-bg.jpg)" }}
      ></div>
    );
  }
}

export default IntroVideoPart;
