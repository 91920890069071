import React from "react";
import $ from "jquery";

import Footer from "../../common/footer/Index";
import Header from "../../common/header/Header2";
import Banner2 from "../../componets/banner/Index2";
import AboutPart from "../../componets/aboutPart/Index";
import WhatWeDoPart from "../../componets/whatWeDoPart/Index";
import ConsultancyPart from "../../componets/ConsultancyPart/Index";
import PostPart from "../../componets/postPart/Index";
import IntroVideoPart from "../../componets/introVideoPart"
import LeadershipPart from "../../componets/LeadershipPart/Index";
import LogoPart from "../../componets/LogoPart/Index";
// import ProgressBarPart from "../../componets/progressBarPart/Index";
// import QuotePart from "../../componets/quotePart/Index";
// import AnswersPart from "../../componets/answersPart/Index";
// import Action2Part from "../../componets/action2Part/Index";
// import LatestNewsPart from "../../componets/latestNewsPart/Index";
// import { SERVER_URL } from "../../config";
// import { composeInitialProps } from "react-i18next";

class Index2 extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: null,
    };
  }

  render() {
    return (
      <>
        <Header changeLang={this.props.changeLang} lang={this.props.lang} />
        <Banner2 lang={this.props.lang} />
        <PostPart lang={this.props.lang} />
        <AboutPart lang={this.props.lang} />
        <ConsultancyPart lang={this.props.lang} />
        <WhatWeDoPart lang={this.props.lang} />
        <IntroVideoPart lang={this.props.lang} />
        <LeadershipPart lang={this.props.lang} />
        <LogoPart lang={this.props.lang}/>
                {/* <ProgressBarPart/>
                <Action2Part/>
                <LatestNewsPart/> */}
           
        <Footer lang={this.props.lang} />
      </>
    );
  }
}

export default Index2;
