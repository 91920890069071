import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

class Nav extends React.Component {
    render() {
        // const { data } = this.props;
        return (
            <>
            {/* <div className="header-left-side text-center text-sm-left">
            <a href="#" onClick={this.props.changeLang}>
                        <FontAwesomeIcon icon={faGlobe} className="mr-1" />
                        <span>{this.props.lang === "English" ? "عربى" : "English"}</span>
                      </a>
            </div> */}
            </>
         );
    }
}

export default Nav;
