import React from 'react';
import Footer from '../common/footer/Index';
import Header from '../common/header/Index';
import ModalVideo from 'react-modal-video'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {pages} from '../../lang.json'

const data = pages.contactus

class ContactPage extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  // componentDidMount() {
	// 	setTimeout(function () {
	// 		document.querySelector(".preloader").style = "display: none";
	// 	}, 5000);
  // }
  openModal = event => {
    this.setState({isOpen: true});
    event.preventDefault();
  }

  formHandle = event => {
    toast.success("Thank you for contact with us!");
    event.preventDefault();
  }

    render() { 
        return ( 
          <>
        <Header lang={this.props.lang} changeLang={this.props.changeLang}/>
        {
          /*====== PAGE TITLE PART START ======*/
        }
        <div className="page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-item text-center">
                  <h2 className="title">{this.props.lang == 'English' ? data.title.en : data.title.ar}</h2>
                  <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>{this.props.lang == 'English' ? data.path.root.en : data.path.root.ar} </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      {this.props.lang == 'English' ? data.path.dest.en : data.path.dest.ar}
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }
        <div>
          {/*====== CONTACT DETAILS PART START ======*/}
          <div className="contact-details-area pt-90 pb-120">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="contact-info mr-30 mt-30">
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-phone" />
                        <h5 className="title">{this.props.lang == 'English' ? data.item[0].en : data.item[0].ar}</h5>
                        <p>966 550850293</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-envelope" />
                        <h5 className="title">{this.props.lang == 'English' ? data.item[1].en : data.item[1].ar}</h5>
                        <p>INFO@ls-education.com</p>
                      </div>
                    </div>
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-map" />
                        <h5 className="title">{this.props.lang == 'English' ? data.item[2].en : data.item[2].ar}</h5>
                        <p>12/A, Riyadh, SA</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-globe" />
                        <h5 className="title">{this.props.lang == 'English' ? data.item[3].en : data.item[3].ar}</h5>
                        <p>ls-education</p>
                      </div>
                    </div>
                  </div>
                  {/* contact info */}
                </div>
                <div className="col-lg-6">
                  <div className="map-area mt-30">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.2459163143235!2d46.713624485215476!3d24.768699055029582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efd9d1cd9bd5b%3A0x3463b430f8ec0d24!2z2KfZhNmC2KfYr9iz2YrYjCDYp9mE2KrYudin2YjZhtiMINin2YTYsdmK2KfYtiAxMjQ3Ng!5e1!3m2!1sar!2ssa!4v1592953026569!5m2!1sar!2ssa"
                      style={{ border: 0 }}
                      allowFullScreen
                      width={600}
                      height={450}
                      title="Our Localtion"
                    />
                  </div>
                  {/* map area */}
                </div>
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {/*====== CONTACT DETAILS PART ENDS ======*/}
          {/*====== GET IN TOUCH PART START ======*/}
          
          {/*====== GET IN TOUCH PART ENDS ======*/}
        </div>;
        <Footer lang={this.props.lang}/>
        </>
      );
    }
}
 
export default ContactPage;