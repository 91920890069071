import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptopCode, faServer, faTools,
} from '@fortawesome/free-solid-svg-icons';

import { pages } from '../../../lang.json'
import { counter } from '@fortawesome/fontawesome-svg-core';

const {consultancy} = pages

class ConsultancyPart extends React.Component {

    render() { 
        return (
          <div className="why-choose-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="section-title text-center">
                    {/* <span>{this.props.lang == 'English' ? consultancy.subtitle.en : consultancy.subtitle.ar}</span> */}
                    <h2 className="title">
                    
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 col-md-4 col-sm-9 wow slideInUp"
                  data-wow-duration=".5s"
                  data-wow-delay="0s"
                >
                  <div className="single-choose text-center mt-30">
                    <div className="icon-box">
                      <span />
                      <FontAwesomeIcon icon={faLaptopCode} size={`4x`} style={{ color: 'white'}}/>
                    </div>
                    <h4 className="title">{this.props.lang == 'English' ? consultancy.item[0].title.en : consultancy.item[0].title.ar}</h4>
                    <p>
                    {this.props.lang == 'English' ? consultancy.item[0].description.en : consultancy.item[0].description.ar}
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-4 col-sm-9 wow slideInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="single-choose text-center mt-30">
                    <div className="icon-box">
                      <span />
                      <FontAwesomeIcon icon={faServer} size={`4x`} style={{ color: 'white'}}/>
                    </div>
                    <h4 className="title">{this.props.lang == 'English' ? consultancy.item[1].title.en : consultancy.item[1].title.ar}</h4>
                    <p>
                    {this.props.lang == 'English' ? consultancy.item[1].description.en : consultancy.item[1].description.ar}
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-4 col-sm-9 wow slideInUp"
                  data-wow-duration=".5s"
                  data-wow-delay="0s"
                >
                  <div className="single-choose text-center mt-30">
                    <div className="icon-box">
                      <span />
                      <FontAwesomeIcon icon={faLaptopCode} size={`4x`} style={{ color: 'white'}}/>
                    </div>
                    <h4 className="title">{this.props.lang == 'English' ? consultancy.item[2].title.en : consultancy.item[2].title.ar}</h4>
                    <p>
                    {this.props.lang == 'English' ? consultancy.item[2].description.en : consultancy.item[2].description.ar}
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <div className="choose-dot">
              <img src="assets/images/choose-dot.png" alt="" />
            </div>
            <div className="choose-shape">
              <img src="assets/images/choose-shape.png" alt="" />
            </div>
          </div>
        );
    }
}
 
export default ConsultancyPart;