import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { about } from "../../../lang.json";

class AboutPart extends React.Component {
  render() {
    return (
      <>
        {/*====== ABOUT PART START ======*/}
        <div className="about-area pt-90 pb-120">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-thumb mt-30">
                  <img src="/assets/images/features-thumb.jpg" alt="" />
                </div>
                {/* about thumb */}
              </div>
              <div className="col-lg-6 col-md-12 order-1 order-lg-2">
              {this.props.lang == 'English' ?
              <div className="about-item mt-30">
                  <span>
                      {about.name.en}
                  </span>
                  <h3 className="title">
                      {about.title.en}
                  </h3>
                  <p>                   
                      {about.description.en}
                  </p>
                  <div className="about-experience">
                    <h3>13</h3>
                    <span>
                      
                        {about.year.en}{" "}
                      <br />                      
                        {about.experience.en}
                    </span>
                  </div>
              
                </div>
                 :
                <div style={{textAlign: 'right'}} className="about-item mt-30 text-sm-right">
                  <span>
                      {about.name.ar}
                  </span>
                  <h3 style={{textAlign: 'right'}} className="title">
                      {about.title.ar}
                  </h3>
                  <p style={{textAlign: 'right'}}>                   
                      {about.description.ar}
                  </p>
                  <div className="about-experience">
                    <h3>13</h3>
                    <span>
                      
                        {about.year.ar}{" "}
                      <br />                      
                        {about.experience.ar}
                    </span>
                  </div>
              
                </div>
                }
                {/* about item */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        ;{/*====== ABOUT PART ENDS ======*/}
      </>
    );
  }
}

export default AboutPart;
