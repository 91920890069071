import React from 'react';

import {whatWeDo} from '../../../lang.json'

const data = whatWeDo.item[0];

class PerformanceManagementPart extends React.Component {

    render() { 
        return ( 
          <div className="services-optimization-area pt-80 pb-120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-lg-1 order-2">
                  <div className="services-optimization-thumb mt-40">
                    <img src="assets/images/qpr-1.png" alt="" />
                  </div>
                  <div className="services-optimization-thumb-2">
                   
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2 order-1">
                  <div className="services-optimization-content mt-40">
                  {this.props.lang == 'English' ?
                    <span style={{fontSize: 20}}>{data.name.en}</span>
                    :
                    <span className="text-sm-right" style={{fontSize: 20}} style={{textAlign: 'right'}}>{data.name.ar}</span>
                    }         
                    {this.props.lang == 'English' ?          
                    <h3 className="title" style={{fontSize: 50}}> {data.title.en}</h3>
                    :
                    <h3 className="title text-sm-right" style={{fontSize: 50}} style={{textAlign: 'right'}}> {data.title.ar}</h3>
                  }                                                            
                    {this.props.lang == 'English'?
                    <ul>                    
                      <li>- {data.description.en0}</li>
                      <li>- {data.description.en1}</li>
                      <li>- {data.description.en2}</li>
                      <li>- {data.description.en3}</li>
                      <li>- {data.description.en4}</li>                                  
                    </ul>
                    :
                    <ul className="text-sm-right" style={{textAlign: 'right'}}>                    
                      <li>{data.description.ar0} -</li>
                      <li>{data.description.ar1} -</li>
                      <li>{data.description.ar2} -</li>
                      <li>{data.description.ar3} -</li>
                      <li>{data.description.ar4} -</li>                                   
                    </ul>
                    }

                    {/* <div className="action-support-item">
                      <p>{this.props.lang == 'English' ? data.button.en : data.button.ar}l</p>
                      <span>966 556264441</span>
                      <i className="fal fa-phone" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
         );
    }
}
 
export default PerformanceManagementPart;