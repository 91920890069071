import React from 'react';
import './Index.scss';
import Footer from '../../common/footer/Index';
import Header from '../../common/header/Index';
import Products from '../../componets/whatWeDoPart/Index';
import { Link } from 'react-router-dom';
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faHeart } from '@fortawesome/free-solid-svg-icons'
import { pages } from "../../../lang.json"
import $ from 'jquery'

const { products } = pages


class ProductPage extends React.Component {


  constructor(props) {
    super(props);
    this.state= {
      data: {  }
    }
  }


    render() {

        const elements = ['1', '2', '3', '4',];

        const items = [];

        for (const [index, value] of elements.entries()) {
            items.push(
              console.log(value),
                <div key={index} className="product-group" data={value}>
                    <div className="product-content">
                        <div className="shop-thumb">
                            <img src="/assets/images/products/{value}.png" alt="" />
                            <div className="shop-overlay">
                                <ul>
                                    <li>
                                        <Link  to={"/shop"}>
                                            <i className="fal fa-shopping-cart" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link  to={"/shop"}>
                                            <i className="fal fa-heart" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="image-popup"
                                            to={"assets/images/products/2.png"}
                                        >
                                            <i className="fal fa-eye" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={`product-price`}>$69</div>
                        </div>



                        <div className="product-info">
                            <Link className="title" to={"/shop"}>
                                <h5>
                                    <strong>{value}</strong> - Creative Digital Agency WordPress Theme
                                </h5>
                            </Link>

                            <div className="vendor-info">
                                <i> by </i>
                                <Link className="R8zaM"  to={"product"}> kalathemes </Link><span> in </span>
                                <Link className="R8zaM" to={"product"}> Fashion </Link>
                            </div>

                            {/*<ul>*/}
                            {/*    <li>$45.99</li>*/}
                            {/*    <li>$30.99</li>*/}
                            {/*</ul>*/}

                        </div>
                    </div>
                </div>
            );
        }

        return (
          <>
            <Header changeLang={this.props.changeLang} lang={this.props.lang} />
            {
              /*====== PAGE TITLE PART START ======*/
            }
            <div className="page-title-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="page-title-item text-center">
                      <h2 className="title">{this.props.lang == "English" ? products.title.en : products.title.ar}</h2>
                      <nav aria-label="breadcrumb">

                      
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link  to={"/"}>{this.props.lang == "English" ? products.path.root.en : products.path.root.ar} </Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                          {this.props.lang == "English" ? products.path.dest.en : products.path.dest.ar}
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* page title */}
                  </div>
                </div>
                {/* row */}
              </div>
              {/* container */}
            </div>
            {
              /*====== PAGE TITLE PART ENDS ======*/
            }
            
            <div className="what-we-do-area gray-bg pt-105 pb-120">
            <Products lang={this.props.lang} />

            {/* container */}
          </div>

            <Footer lang={this.props.lang}/>
            </>
         );
    }
}

export default ProductPage;
