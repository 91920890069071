import React, { Component, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import $ from 'jquery'

// import { FormattedMessage } from 'react-intl';
// import LanguageProvider from '../LanguageProvider';
// import { AppLocaleList, DEFAULT_LOCALE } from '../i18n';
// import messages from '../appMessages';

import NoMatch from "./pages/Error";
import Index1 from "./pages/home/Index";
import Index2 from "./pages/home/Index-2";
import Index3 from "./pages/home/Index-3";
import Index4 from "./pages/home/Index-4";
import AboutPage from "./pages/about/About";
import ContactPage from "./pages/Contact";
import ServicesPage from "./pages/service/Index";
import ServicesDetails from "./pages/service/Details";
import CaseStudy from "./pages/Case-study";
import CaseDetails from "./pages/Case-details";
import ComingSoon from "./pages/Coming-soon";
import FaqPage from "./pages/Faq";
import PricingPage from "./pages/Pricing";
import ShopPage from "./pages/Shop";
import ShopDetailsPage from "./pages/Shop-details";
import ProductPage from "./pages/product/Index";
import TeamPage from "./pages/Team";
import TeamDetailsPage from "./pages/Team-details";
import BlogStandard from "./pages/blog/Blog-standard";
import BlogGrid from "./pages/blog/Blog-grid";
import BlogDetails from "./pages/blog/Blog-details";
import ProductDetailPage from "./pages/product-details/Index";
import ConsultancyPage from "./pages/consultancy/Consultancy";
import PerformanceManagementPage from "./pages/OurProducts/PerformanceManagement";
import DataMiningPage from "./pages/OurProducts/DataMining";
import ProcessDesignerPage from "./pages/OurProducts/ProcessDesigner";
import ProjectManagmentPage from "./pages/OurProducts/ProjectManagment";
import MDMPage from "./pages/OurProducts/MDM";
import FirewallManagmentPage from "./pages/OurProducts/FirewallManagment";
import TrustedIdentityPage from "./pages/OurProducts/TrustedIdentity";
import WebApplicationFirewallPage from "./pages/OurProducts/WebApplicationFirewall";
import SecureItPage from "./pages/OurProducts/SecureIT";
import PrivilegedAccessSecurityPage from "./pages/OurProducts/PrivilegedAccessSecurity";
import ProtectEmailSystemPage from "./pages/OurProducts/ProtectEmailSystem";
import AttacksDetectionSolutionPage from "./pages/OurProducts/AttacksDetectionSolution";
import InternetSecurityPage from "./pages/OurProducts/InternetSecurity";
import PhishingSimulatorPage from "./pages/OurProducts/PhishingSimulator";
import SingleSignOnSolutionPage from "./pages/OurProducts/SingleSignOnSolution";
import OnlineAssessmentsPage from "./pages/OurProducts/OnlineAssessments";
import RoboticProcessAutomationPage from "./pages/OurProducts/RoboticProcessAutomation";
import ProcessManagementPage from "./pages/OurProducts/ProcessManagement";
import MobileAppPage from "./pages/OurProducts/MobileApp";
import BadrAldweesh from "./pages/BadrAldweesh";

import "../assets/css/fonts.css";

import Preloader from "./common/preloader/Index";

class App extends Component {

  constructor() {
    super();
    this.state = {
      posts: null,
      lang: null,
    };
    this.changeLang = this.changeLang.bind(this);
  }

  componentDidMount() {
    this.setState({ lang: !window.lang ? 'English' : window.lang })
    window.lang = this.state.lang
  }

  changeLang() {
    let currentLang = this.state.lang;

    if (currentLang === "English") {
      this.setState({ lang: "عربى" });
      $("body").removeClass("english");
      $("body").addClass("arabic");
    } else {
      this.setState({ lang: "English" });
      $("body").removeClass("arabic");
      $("body").addClass("english");
    }

    window.lang = this.state.lang
  }

  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Preloader />
        <Switch>
          <Route exact path={`/`} component={() => <Index2 lang={this.state.lang} changeLang={this.changeLang} />} />

          <Route
            path={`/about`}
            component={() => <AboutPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/services`}
            component={() => <ServicesPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/services-details`}
            component={ServicesDetails}
          />

          <Route
            path={`/case-study`}
            component={CaseStudy}
          />
          <Route
            path={`/case-details`}
            component={CaseDetails}
          />
          <Route
            path={`/coming-soon`}
            component={ComingSoon}
          />
          <Route path={`/error`} component={NoMatch} />
          <Route path={`/faq`} component={FaqPage} />
          <Route
            path={`/pricing`}
            component={PricingPage}
          />

          <Route
            path={`/products`}
            component={() => <ProductPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/products-details`}
            component={ProductDetailPage}
          />
          <Route path={`/shop`} component={ShopPage} />

          <Route
            path={`/shop-details`}
            component={ShopDetailsPage}
          />
          <Route path={`/team`} component={TeamPage} />
          <Route
            path={`/team-details`}
            component={TeamDetailsPage}
          />
          <Route
            path={`/blog-standard`}
            component={BlogStandard}
          />
          <Route
            path={`/blog-grid`}
            component={BlogGrid}
          />
          <Route
            path={`/blog-details`}
            component={BlogDetails}
          />

          <Route
            path={`/contact`}
            component={() => <ContactPage lang={this.state.lang} changeLang={this.changeLang} />}
          />

          <Route
            path={`/consultancy`}
            component={() => <ConsultancyPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/performance-management`}
            component={() => <PerformanceManagementPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/data-mining`}
            component={() => <DataMiningPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/process-designer`}
            component={() => <ProcessDesignerPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/management`}
            component={() => <ProcessManagementPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/project-management`}
            component={() => <ProjectManagmentPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route path={`/mdm`} component={() => <MDMPage lang={this.state.lang} changeLang={this.changeLang} />} />
          <Route
            path={`/firewall-management`}
            component={() => <FirewallManagmentPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/trusted-identity`}
            component={() => <TrustedIdentityPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/web-application-firewall`}
            component={() => <WebApplicationFirewallPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/mobile-app`}
            component={() => <MobileAppPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/secure-it`}
            component={() => <SecureItPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/privilege-access-security`}
            component={() => <PrivilegedAccessSecurityPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/protect-email-system`}
            component={() => <ProtectEmailSystemPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/attacks-detection-solution`}
            component={() => <AttacksDetectionSolutionPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/Internet-security`}
            component={() => <InternetSecurityPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/phishing-simulator`}
            component={() => <PhishingSimulatorPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/single-sign-on-solution`}
            component={() => <SingleSignOnSolutionPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/online-assessments`}
            component={() => <OnlineAssessmentsPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/robotic-process`}
            component={() => <RoboticProcessAutomationPage lang={this.state.lang} changeLang={this.changeLang} />}
          />
          <Route
            path={`/Badr-Aldweesh`}
            component={() => <BadrAldweesh lang={this.state.lang} changeLang={this.changeLang} />}
          />

          <Route component={() => <NoMatch lang={this.state.lang} changeLang={this.changeLang} />} />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

export default App;
