import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
} from '@fortawesome/free-solid-svg-icons';
import {faLongArrowAltRight,faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import { whatWeDo } from '../../../lang.json'

class WhatWeDoPart extends React.Component {

    render() {
        return (
          <div className="what-we-do-area gray-bg pt-105 pb-120">
            <div className="container">
              <div className="row">
              {this.props.lang=="English" ? 
                <div className="col-lg-7 col-md-7">
                  <div className="section-title-2">
                    <h2 className="title">{ whatWeDo.name.en }</h2>
                    <p>
                      {whatWeDo.description.en }
                    </p>
                  </div>
                  {/* section title 2 */}
                </div>
                :   
                <>             
                <div className="col-lg-8 col-md-7">
                  <div className="section-title-2">
                    <h2 className="title"></h2>
                    <p>
                      
                    </p>
                  </div>
                  {/* section title 2 */}
                </div>
                
                <div className="col-lg-4 col-md-7">
                <div className="section-title-2">
                  <h2 className="title">{whatWeDo.name.ar}</h2>
                  <p>
                    { whatWeDo.description.ar}
                  </p>
                </div>
                {/* section title 2 */}
              </div>
              </>
                }
              </div>
              <div className="row">
              {this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"performance-management"}>                      
                     <h6> {whatWeDo.item[0].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"performance-management"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[0].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }

{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"data-mining"}>                      
                     <h6> {whatWeDo.item[1].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"data-mining"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[1].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }

{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"process-designer"}>                      
                     <h6> {whatWeDo.item[2].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"process-designer"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[2].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }




{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"project-management"}>                      
                     <h6> {whatWeDo.item[4].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"project-management"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[4].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"mdm"}>                      
                     <h6> {whatWeDo.item[5].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"mdm"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[5].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"firewall-management"}>                      
                     <h6> {whatWeDo.item[6].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"firewall-management"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[6].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }

{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"trusted-identity"}>                      
                     <h6> {whatWeDo.item[7].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"trusted-identity"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[7].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }

{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"web-application-firewall"}>                      
                     <h6> {whatWeDo.item[8].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"web-application-firewall"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[8].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"mobile-app"}>                      
                     <h6> {whatWeDo.item[9].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"mobile-app"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[9].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"secure-it"}>                      
                     <h6> {whatWeDo.item[10].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"secure-it"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[10].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"privilege-access-security"}>                      
                     <h6> {whatWeDo.item[11].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"privilege-access-security"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[11].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"protect-email-system"}>                      
                     <h6> {whatWeDo.item[12].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"protect-email-system"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[12].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"attacks-detection-solution"}>                      
                     <h6> {whatWeDo.item[13].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"attacks-detection-solution"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[13].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }
                {this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"internet-security"}>                      
                     <h6> {whatWeDo.item[14].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"internet-security"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[14].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }

{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"phishing-simulator"}>                      
                     <h6> {whatWeDo.item[15].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"phishing-simulator"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[15].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }




{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"online-assessments"}>                      
                     <h6> {whatWeDo.item[17].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"online-assessments"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[17].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }


{this.props.lang=="English" ?
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"robotic-process"}>                      
                     <h6> {whatWeDo.item[18].en} </h6>
                     <FontAwesomeIcon icon={faLongArrowAltRight} />                                     
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                :
                <div className="col-lg-4 col-md-6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"robotic-process"}> 
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />                        
                     <h6> {whatWeDo.item[18].ar} </h6>                                                       
                    </Link>
                  </div>
                  {/* what we do item */}
                </div>
                }



                
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
         );
    }
}

export default WhatWeDoPart;
