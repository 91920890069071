import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import { banner } from '../../../lang.json'

class Banner2 extends React.Component {

    render() {
        return (
          <div className="banner-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <div className="banner-content pt-100">
                    <span className="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeInLeft'}}>{this.props.lang == "English" ? banner.name.en : banner.name.ar}</span>
                    <h1 className="title wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".5s" style={{visibility: 'visible', animationDuration: '2s', animationDelay: '0.5s', animationName: 'fadeInLeft'}}>{this.props.lang == "English" ? banner.title.en : banner.title.ar}</h1>
                    <ul>

                    <li>
                        <Link className="fadeInUp wow" data-wow-duration="1s" data-wow-delay="1s" to={"Products"}
                              style={{visibility: 'visible', animationDuration: '1s', animationDelay: '1s', animationName: 'fadeInUp'}}>
                            <span>


                        <FontAwesomeIcon icon={faGem} />
                    </span>
                        <p>{this.props.lang == "English" ? banner.item[0].en : banner.item[0].ar}</p>
                      </Link></li>
                      {/* <li>
                          <Link className="btn-3 fadeInUp wow" data-wow-duration="1s" data-wow-delay="1.3s" to={"consultancy"}
                                style={{visibility: 'visible', animationDuration: '1s', animationDelay: '1s', animationName: 'fadeInUp'}}>
                              <span>


                          <FontAwesomeIcon icon={faGem} />
                      </span>
                          <p>{this.props.lang == "English" ? banner.item[1].en : banner.item[1].ar}</p>
                        </Link></li> */}
                      <li><Link className="btn-2 fadeInUp wow" data-wow-duration="1s" data-wow-delay="1.5s" to={"services"} style={{visibility: 'visible', animationDuration: '1s', animationDelay: '1.5s', animationName: 'fadeInUp'}}><span>
                           <FontAwesomeIcon icon={faGem} />
                      </span>
                          <p>{this.props.lang == "English" ? banner.item[2].en : banner.item[2].ar}</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="banner-shape d-none d-lg-block col-lg-5">
                <img src="/assets/images/banner-man.png" alt="" />
              </div>
            </div>
          </div>
         );
    }
}

export default Banner2;
