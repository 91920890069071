import React from 'react';
import { Link } from 'react-router-dom';
import { footer } from "../../../lang.json"

class Footer extends React.Component {

  render() {
      return (
        <>
        <footer className="footer-area footer-area-2 footer-area-1 bg_cover" style={{backgroundImage: 'url(assets/images/footer-bg.jpg)'}}>
          <div className="footer-overlay">
            <div className="container position-relative">
              <div className="row">
                <div className="col-lg-4 col-md-7">
                  <div className="widget-item-2 mt-30">
                    <h4 className="title">{this.props.lang == "English" ? footer.pages.en : footer.pages.ar }</h4>
                    <div className="footer-list">
                      <ul>
                        <li><Link to={"/"}><i className="fal fa-angle-right" /> {this.props.lang == "English" ? footer.pages.item[0].en : footer.pages.item[0].ar }</Link></li>
                        <li><Link to={"services"}><i className="fal fa-angle-right" /> {this.props.lang == "English" ? footer.pages.item[1].en : footer.pages.item[1].ar }</Link></li>
                        {/* <li><Link to={"about"}><i className="fal fa-angle-right" /> About</Link></li> */}                       
         
                      </ul>
                      <ul>
                        <li><Link to={"career"}><i className="fal fa-angle-right" /> {this.props.lang == "English" ? footer.pages.item[2].en : footer.pages.item[2].ar }</Link></li>
                        <li><Link to={"contact"}><i className="fal fa-angle-right" /> {this.props.lang == "English" ? footer.pages.item[3].en : footer.pages.item[3].ar }</Link></li>
                      </ul>
                    </div>
                  </div> {/* widget item 2 */}
                </div>
                <div className="col-lg-3 offset-lg-1 col-md-5">
                  
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="widget-item-2 widget-item-3 mt-30">
                    <h4 className="title">{this.props.lang == "English" ? footer.workingHours.en : footer.workingHours.ar }</h4>
                    <ul>
                      <li>{this.props.lang == "English" ? footer.date.en : footer.date.ar }: 7:00 - 17:00</li>
                     
                    </ul>
                    {/* <p><span>{this.props.lang == "English" ? footer.years.en : footer.years.ar }</span> {this.props.lang == "English" ? footer.description.en : footer.description.ar }</p> */}
                    {/* <Link  to={"/"}><i className="fal fa-angle-right" />{this.props.lang == "English" ? footer.discoverMore.en : footer.discoverMore.ar }</Link> */}
                  </div> {/* widget item 3 */}
                </div>
              </div> {/* row */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="footer-copyright">
                    <p>{this.props.lang == "English" ? footer.copyright.en : footer.copyright.ar } <span>{this.props.lang == "English" ? footer.by.en : footer.by.ar }</span> - {(new Date().getFullYear())}</p>
                  </div> {/* footer copyright */}
                </div>
              </div> {/* row */}
            </div> {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className="back-to-top back-to-top-2">
          <a  href="#backTotop">
            <i className="fas fa-arrow-up" />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}
      </>
    );
  }
}

export default Footer;
