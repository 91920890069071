import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../common/footer/Index';
import Header from '../../common/header/Index';

import ConsultancyPart from '../../componets/ConsultancyPart/Index';

import { pages } from '../../../lang.json'
import { contains } from 'jquery';

const { consultancy } = pages

class AboutPage extends React.Component {

    render() { 
        return ( 
          <>
          <Header lang={this.props.lang} changeLang={this.props.changeLang}/>
          {
            /*====== PAGE TITLE PART START ======*/
          }
          <div className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-item text-center">
                    <h2 className="title">{this.props.lang == "English" ? consultancy.title.en : consultancy.title.ar}</h2>
                    <nav aria-label="breadcrumb">

                    
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to={'index'}>{this.props.lang == "English" ? consultancy.path.root.en : consultancy.title.ar}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          {this.props.lang == "English" ? consultancy.path.dest.en : consultancy.path.dest.ar}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* page title */}
                </div>
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {
            /*====== PAGE TITLE PART ENDS ======*/
          }
            <ConsultancyPart lang={this.props.lang}/>
            <Footer lang={this.props.lang}/>
            </>
         );
    }
}
 
export default AboutPage;