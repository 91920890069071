import React from 'react';
import Footer from '../../common/footer/Index';
import Header from '../../common/header/Index';
// import DataMiningPart from '../../componets/DataMiningPart/Index';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import { whatWeDo } from '../../../lang.json'
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  item_container: {
    padding: '1rem'
  },
  item_sub_container: {
    backgroundColor: '#81a3bb',
    width: '100%',
    height: '10rem',
    padding: '1rem'
  },
  item_title: {
    fontSize: '1.7rem',
    color: '#000'
  },
  item_bar: {
    width: 8,
    height: '50%',
    position: 'relative',
    backgroundColor: '#fff',
    top: -56,
    left: -16
  },
  assesment_txt: {
    fontSize: '2.3rem',
    color: '#000',
    margin: '1rem'
  },
  online_txt: {
    fontSize: '3rem',
    color: '#000',
    margin: '1rem'
  },
  header_container: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    marginTop: 200,
    marginBottom: 200
  }
})

const data = whatWeDo.item[17].item
const title = whatWeDo.item[17]

class OnlineAsessment extends React.Component {


// export default function OnlineAsessment(props) {
   
  render() {   
    const { classes } = this.props;
  return (
    <>
      <Header lang={this.props.lang} changeLang={this.props.changeLang} />
        <div className="page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-item text-center">
                  <h2 className="title">{this.props.lang == 'English' ? title.en : title.ar}</h2>

                  <nav aria-label="breadcrumb">                      
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>{this.props.lang == 'English' ? 'Home' : 'الرئيسية'} </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      {this.props.lang == 'English' ? 'Online Assessments' : 'التقييم الإلكتروني'}
                      </li>
                    </ol>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      
      <Grid container className={classes.container}>
        {/* <Grid item xs={12} className={classes.header_container}>
          <div className={classes.online_txt}>ONLINE</div>
          <div className={classes.assesment_txt}>ASSESSMENTS</div>
        </Grid> */}
        {
          data.map((val, index) => {
            return (
              <Grid key={index} item xs={6} sm={3} className={classes.item_container}>
                <div className={classes.item_sub_container}>
                  <div className={classes.item_title}>{this.props.lang == 'English' ? val.en : val.ar}</div>
                </div>
              </Grid>
            )
          })
        }
      </Grid>
      {/* <OnlineAsessmentPart lang={this.props.lang} /> */}
      <Footer lang={this.props.lang} />
    </>
  );
      }
}

export default withStyles(useStyles)(OnlineAsessment);