import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import $ from "jquery";
import SocialLink from "./SocialLink";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import { FormattedMessage, FormattedDate, FormattedNumber } from "react-intl";

class Header extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <>
        <header className="header-area header-area-2">
          <div className="header-top pl-30 pr-30">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-7">
                <div className="header-left-side text-center text-sm-left">
                  <ul>
                    <li>
                      <a href="mailto:INFO@LS-EDUCATION.COM" target="__blank">
                        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                        INFO@LS-EDUCATION.COM
                      </a>
                    </li>
                    <li>
                      <a href="tel:786 875 864 75 7">
                        <FontAwesomeIcon icon={faPhone} className="mr-1" />
                        +966 550850293
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={this.props.changeLang}>
                        <FontAwesomeIcon icon={faGlobe} className="mr-1" />
                        <span>{this.props.lang === "English" ? "عربى" : "English"}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-5">
                <div className="header-right-social text-center text-sm-right">
                  <SocialLink />
                </div>
              </div>
            </div>
          </div>
          <div className="header-nav">
            <div className="navigation">
              <nav className="navbar navbar-expand-lg navbar-light ">
                <Link className="navbar-brand" to={"/"}>
                  <img
                    src="assets/images/logo.png"
                    alt=""
                    width="200"
                    height="100"
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <a href="#" onClick={this.props.changeLang}>
                        <FontAwesomeIcon icon={faGlobe} className="mr-1" />
                        <span>{this.props.lang === "English" ? "عربى" : "English"}</span>
                      </a>
                </button>
                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <Nav />
                </div>
                <div className="navbar-btn mr-100">
                  {/* <Link className="main-btn"  to={'/contact'}>
                    <FormattedMessage
                    id='Contact_Us.label'
                    defaultMessage={['Contact_Us.label']}
                    values=''
                  />
                  </Link> */}
                </div>
              </nav>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
