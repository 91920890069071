import React from "react";
//import ReactDOM from 'react-dom'
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { video as data } from '../../../lang.json'


class IntroVideoPart extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      newsData: {
        title: "",
        content: "",
        image: "",
      },
    };
    this.openModal = this.openModal.bind(this);
  }

  // componentDidMount() {
  //   axios.get(() => {
  //     yurl:'gggg'
  //   }).then((data) => {
  //     this.setState({newsData: data})
  //   })
  // }

  openModal(e) {
    e.preventDefault();
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div
        className="intro-video-area bg_cover"
        style={{ backgroundImage: "url(assets/images/intro-video-bg.jpg)" }}
      >
        <div className="intro-overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
              {this.props.lang == 'English' ?
                <div className="intro-video-content mt-30">                
                  <span>{data.name.en}</span>
                  <h2 className="title">{this.state.newsData.title}</h2>
                  <p className="text-1">
                  {data.title.en}
                  </p>
                  <p className="text-2">
                  {data.description.en}
                  </p>                  
                  <Link
                    className="main-btn wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".1s"
                    to={"/performance-management"}
                  >
                    {data.more.en}
                  </Link>
                  
                </div>
                :
                <div className="intro-video-content mt-30 text-sm-right" style={{textAlign: 'right'}}>                
                <span>{data.name.ar}</span>
                <h2 className="title">{this.state.newsData.title}</h2>
                <p className="text-1">
                {data.title.ar}
                </p>
                <p className="text-2">
                {data.description.ar}
                </p>                  
                <Link
                  className="main-btn wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay=".1s"
                  to={"/performance-management"}
                >
                  {data.more.ar}
                </Link>
                
              </div>
                }
                {/* intro video content */}
              </div>
              <div className="col-lg-6">
                <div className="intro-thumb mt-30">
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="tmQFRdZwIsI"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <img src="assets/images/video-item-1.png" alt="" />
                  <Link
                    className="video-popup"
                    to={"https://youtu.be/tmQFRdZwIsI"}
                    onClick={this.openModal}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      size={`3x`}
                      className={`pl-2 pt-3`}
                    />
                  </Link>
                </div>
                {/* intro thumb */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
      </div>
    );
  }
}

export default IntroVideoPart;
